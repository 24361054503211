import React, { useEffect, useState }from 'react'
import * as S from '../desconto/styles'
import { graphql, navigate, withPrefix  } from 'gatsby'
import Layout from '../../../components/totem/layout'
import { descicon, iconvendedor } from '../../../images/totem'
import PropTypes, { string } from 'prop-types'
import { ditoTrack } from '../../../helpers/dito'
import BtnVoltar from '../../../components/totem/Voltar'

const OfertasInternaTotem = ({ oferta, pageContext: { loja } }) => {
  const [items, setItems] = useState([]);
  const inactivityTime = function () {
    let time;
        // reset timer
        window.onload = resetTimer;
        document.onmousemove = resetTimer;
        document.onkeydown = resetTimer;
        function doSomething() {
          setTimeout(() => {
            navigate(`/br/totem/${loja.slug}/`)
          }, 30000);
        }
        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(doSomething, 30000)
        }    
    };
  
  useEffect(() => {
    const items = !!localStorage.getItem("sem-cadastro");
    setItems(items)
    if(items == true){
      navigate(`/totem/${loja.slug}/cadastro`)
    }else{
      const nomeoferta = localStorage.getItem("nome oferta");
      ditoTrack('concluiu-oferta');
      ditoTrack(nomeoferta)
      localStorage.setItem("concluiu-oferta", true);
      inactivityTime()
    }
  }, []);

  const [Itemofertas, setItemofertas] = useState('');

  useEffect(() => {
    const ofertas = localStorage.getItem("Ofertas");
    setItemofertas(ofertas)
  }, []);

  return (
    <Layout loja={loja}>
      <S.DescontoTotem>
        <div className="title">
          <img src={descicon} alt="" />
          
        </div>
        <div
          className="desconto"
          dangerouslySetInnerHTML={{ __html: Itemofertas }}
        />
        <div className="vendedor">
          <img src={iconvendedor} alt="" />
          <p>CHAME UM VENDEDOR</p>
        </div>
        {/* <div className="infDesconto">
          <p>
            A OFERTA FOI ENVIADA PARA O E-MAIL CADASTRADO<br></br> VÁLIDA TAMBÉM
            PARA A LOJA ONLINE
          </p>
        </div> */}
      </S.DescontoTotem>
      <BtnVoltar></BtnVoltar>
    </Layout>
  )
}


OfertasInternaTotem.propTypes = {
  oferta: PropTypes.string,
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default OfertasInternaTotem

export const query = graphql`
  query ($language: String!, $ofertaId: String!) {
    totemOferta(id: { eq: $ofertaId }) {
      oferta
      titulo
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
